(function($) {
    $(document).ready(function() {

        const mobileQuery = window.matchMedia("(max-width: 767px)");
        let clickHandler;

        function handleMobileChange(e) {
            if (e.matches) {
            initMobileTaps();
            } else {
            cleanupMobile();
            }
        }

        function initMobileTaps() {
            clickHandler = function(e) {
            const $menuItem = $(e.target).closest('li:has(> .sub-menu)');
            
            if (!$menuItem.length || !$(e.target).closest('a').length) return;

            if (!$menuItem.hasClass('tapped')) {
                e.preventDefault();
                $('li:has(> .sub-menu)').not($menuItem).removeClass('tapped');
                $menuItem.addClass('tapped');
            }
            };

            $(document).on('click', clickHandler);
        }

        function cleanupMobile() {
            if (clickHandler) {
            $(document).off('click', clickHandler);
            clickHandler = null;
            }
            $('li').removeClass('tapped');
        }

        // Initialize and listen for changes using addEventListener instead of addListener
        mobileQuery.addEventListener('change', handleMobileChange);
        handleMobileChange(mobileQuery);



        // Handle menu toggle
        const $menuToggle = $('.menu-toggle');
        const $nav = $('.menu-top-nav');
        
        // Exit if elements don't exist
        if (!$menuToggle.length || !$nav.length) return;

        // Simple debounce function
        function debounce(func, wait) {
            let timeout;
            return function executedFunction(...args) {
                const later = () => {
                    clearTimeout(timeout);
                    func.apply(this, args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        }

        // Click handler
        const handleClick = debounce(function(e) {
            e.preventDefault();
            const $this = $(this);
            const isExpanded = !$this.hasClass('on');  // Toggle state
            
            // Toggle button classes
            $this
                .toggleClass('off on')
                .attr('aria-expanded', isExpanded);
            
            // Toggle navigation classes
            $nav.toggleClass('menu-closed menu-opened');
            
            // Trigger custom event for external listeners
            $this.trigger('menuToggle', [isExpanded]);
        }, 250);

        // Set initial state
        $nav.addClass('menu-closed');
        $menuToggle
            .addClass('off')
            .attr({
                'tabindex': '0',
                'aria-expanded': 'false'
            });

        try {
            $menuToggle
                .on('click', handleClick)
                .on('keypress', function(e) {
                    if (e.which === 13 || e.which === 32) {  // Enter or Space
                        e.preventDefault();
                        $(this).trigger('click');
                    }
                })
                .on('mouseenter mouseleave', function(e) {
                    if (window.matchMedia('(hover: hover)').matches) {
                        $(this).toggleClass('hovered', e.type === 'mouseenter');
                    }
                });

            // Close menu on escape key
            $(document).on('keyup', function(e) {
                if (e.key === 'Escape' && $menuToggle.hasClass('on')) {
                    $menuToggle.trigger('click');
                }
            });

            // Clean up on page unload
            $(window).on('unload', function() {
                $menuToggle.off();
                $(document).off('keyup');
            });

        } catch (error) {
            console.error('Menu toggle initialization error:', error);
        }
    });
}(jQuery));