// Keep this for hot-loading to work properly
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(console.error);
}

import "jquery";
import "magnific-popup";
// import "zenscroll";
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import "lazysizes";
import "lazysizes/plugins/unload/ls.unload";

// import "./gravityforms/collapsible-sections.js";
import "./gravityforms/debugging.js";
// import "./LowPolyGenerator.js";

import "./menu-toggle.js";

window.Swiper = Swiper;

(function($) {

  $('[class*="menu-item"], .hoverable').hover(
      function() {
          $(this).addClass('hovered');
          $(this).parents('[class*="menu-item"]').addClass('hovered');
      },
      function() {
          if (!$(this).find(':hover').length) {
              $(this).removeClass('hovered');
              $(this).parents('[class*="menu-item"]').each(function() {
                  if (!$(this).find(':hover').length) {
                      $(this).removeClass('hovered');
                  }
              });
          }
      }
  );
  

  $('.widget_recent_entries a').each(function() {
    var words = $(this).text().split(' ');
    $(this).html(words.map(word => `<span>${word}</span>`).join(' '));
  });

  $('.widget_block').each(function() {
      var paragraphs = $(this).find('p');
      var emptyParagraphs = paragraphs.filter(':empty');
      
      emptyParagraphs.addClass('empty');
      
      if (paragraphs.length === 1 && emptyParagraphs.length === 1) {
          $(this).addClass('empty');
      }
  });


  // var interested = [];
  // $("#sendMessage").click(function () {
  //   $("input[name='interest']:checked").each(function () {
  //     interested.push($(this).val());
  //   });
  //   $("#interested_in").val(interested);
  // });

const $testimonialSwiper = $('.buckup-testimonials');

if ($testimonialSwiper.length) {
  // const $swiperContainer = $testimonialSwiper.find('.swiper-container');
  // const dataSlides = parseInt($swiperContainer.data('slides')) || 4;
  // const slidesFor1024 = Math.min(dataSlides, 5);
  // const slidesFor768 = dataSlides > 3 ? 3 : dataSlides;

  new Swiper('.buckup-testimonials .swiper-container', {
    modules: [Navigation, Pagination, Autoplay, Keyboard],
    slidesPerView: 4,
    centeredSlides: true,
    initialSlide: 2,
    speed: 1500,
    autoplay: false,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  });
}

// document.addEventListener('DOMContentLoaded', () => {
//     // Select all swiper containers within wp-swiper elements
//     const wpSwiperContainers = document.querySelectorAll('.wp-swiper .swiper-container');
    
//     // Initialize each swiper
//     wpSwiperContainers.forEach(container => {
//         // Get the configuration from data-swiper attribute
//         const config = JSON.parse(container.dataset.swiper);
        
//         // Create new Swiper instance with the parsed config
//         new Swiper(container, {
//             ...config,
//             // Ensure navigation elements are scoped to this container
//             navigation: config.navigation ? {
//                 nextEl: container.parentElement.querySelector('.swiper-button-next'),
//                 prevEl: container.parentElement.querySelector('.swiper-button-prev')
//             } : false,
//             // Ensure pagination is scoped to this container
//             pagination: config.pagination ? {
//                 el: container.parentElement.querySelector('.swiper-pagination'),
//                 ...config.pagination
//             } : false
//         });
//     });
// });


document.addEventListener('DOMContentLoaded', () => {
    const wpSwiperContainers = document.querySelectorAll('.wp-swiper .swiper-container');
    
    // Function to update swiper widths
    const updateSwiperWidths = () => {
        wpSwiperContainers.forEach(container => {
            const width = window.innerWidth < 768 ? '100vw' : `${container.closest('.ct-span').offsetWidth}px`;
            container.style.width = width;
        });
    };

    // Initialize swipers
    wpSwiperContainers.forEach(container => {
        // Set initial width
        const width = window.innerWidth < 768 ? '100vw' : `${container.closest('.ct-span').offsetWidth}px`;
        container.style.width = width;
        
        const config = JSON.parse(container.dataset.swiper);
        const slidesPerView = parseInt(config.slidesPerView);

        const breakpointConfig = (slidesPerView > 1 && !config.breakpoints) ? {
            breakpoints: {
                1024: {
                    slidesPerView: slidesPerView
                },
                768: {
                    slidesPerView: Math.min(3, slidesPerView)
                },
                640: {
                    slidesPerView: Math.min(2, slidesPerView)
                },
                320: {
                    slidesPerView: 1
                }
            }
        } : {};

        new Swiper(container, {
          modules: [Navigation, Pagination, Autoplay, Keyboard],
            ...config,
            ...breakpointConfig,
            navigation: config.navigation ? {
                nextEl: container.parentElement.querySelector('.swiper-button-next'),
                prevEl: container.parentElement.querySelector('.swiper-button-prev')
            } : false,
            pagination: config.pagination ? {
                el: container.parentElement.querySelector('.swiper-pagination'),
                ...config.pagination
            } : false
        });
    });

    // Add resize event listener
    window.addEventListener('resize', updateSwiperWidths);
});







const $clientSwiper = $('#clients');

if ($clientSwiper.length) {
  new Swiper('#clients .swiper-container', {
    modules: [Navigation, Pagination, Autoplay, Keyboard],
    slidesPerView: 'auto',
    centeredSlides: true,
    // initialSlide: 2,
    speed: 2000,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 40
      }
    }
  }); 

}




const $projectSwiper = $('.project-container');

if ($projectSwiper.length) {
  new Swiper('.project-container', {
    modules: [Navigation, Pagination, Autoplay, Keyboard],
    direction: 'horizontal',
    lazy: true,
    speed: 1500,
    autoplay: false, // Disabled by default for mobile
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      // Tablet and larger (768px and up)
      768: {
        direction: 'vertical',
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
      }
    }
  });
}







const initServiceSwiper = () => {
  const $serviceFeatures = $('.service-features-container.swiper');

  if (!$serviceFeatures.length) {
    return null;
  }

  const serviceSwiper = new Swiper('.service-features-container.swiper', {
    modules: [Navigation, Pagination, Autoplay, Keyboard],
    direction: 'vertical',
    // initialSlide: 3,
    // centeredSlides: true,
    slidesPerView: 5,
    watchOverflow: true,
    observer: true,
    observeParents: true,
    loop: true,
    lazy: true,
    speed: 1500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    on: {
      // init: function() {
      //   updateSwiperHeight(this);
      // },
      slideChange: function () {
        // Determine how to reset classes when the loop restarts
        const activeIndex = this.activeIndex;
        const slidesLength = this.slides.length;

        // This assumes a loop's "restart" is detected when index goes from high to low
        if (activeIndex < slidesLength / 2) {
          // Loop has restarted, reset classes on all slides
          this.slides.forEach(slide => {
            slide.classList.remove('swiper-slide-visited');
          });
        }

        // Apply 'swiper-slide-visited' to all slides before the current active slide
        for (let i = 0; i < activeIndex; i++) {
          const slide = this.slides[i];
          slide.classList.add('swiper-slide-visited');
        }
      },
    },
  });

  // Event handlers
  $('#service-features').hover(
    () => serviceSwiper.autoplay.stop(),
    () => serviceSwiper.autoplay.start()
  );

  // $(window).on('resize', () => updateSwiperHeight(serviceSwiper));

  return serviceSwiper;
};



// // Store swiper instance reference
// let mySwiper;

// // Initialize swiper and attach event listeners
// function initializeSwiperWithLazy() {
//   const swiperElement = document.querySelector('.swiper');
  
//   // Listen for unload
//   swiperElement.addEventListener('lazyafterunload', () => {
//     if (mySwiper) {
//       mySwiper.destroy(true, true); // Destroy swiper instance
//       mySwiper = null;
//     }
//   });

//   // Listen for reload
//   swiperElement.addEventListener('lazyloaded', () => {
//     if (!mySwiper) {
//       mySwiper = new Swiper('.swiper', {
//         // your swiper options here
//       });
//     }
//   });

//   // Initial swiper setup
//   mySwiper = new Swiper('.swiper', {
//     // your swiper options here
//   });
// }


// // Helper function to update swiper height
// const updateSwiperHeight = (swiper) => {
//   const $slides = $(swiper.slides);
//   const slideHeight = $slides.first().outerHeight();
//   const spaceBetween = swiper.params.spaceBetween;
//   const totalHeight = (slideHeight * swiper.params.slidesPerView) + 
//                      (spaceBetween * (swiper.params.slidesPerView - 1));
//   $(swiper.el).height(totalHeight);
  

//   swiper.updateAutoHeight();
//   swiper.update();
// };

// Initialize swiper
initServiceSwiper();






const initCustomSwipers = () => {
    const swiperContainers = document.querySelectorAll('.custom-swiper-container');
    
    if (!swiperContainers.length) return;
    
    swiperContainers.forEach(container => {
        const dataset = container.dataset;
        
        const config = {
            modules: [Navigation, Pagination, Autoplay, Keyboard],
            direction: dataset.direction || 'horizontal',
            slidesPerView: dataset.slidesPerView || 'auto',
            spaceBetween: parseInt(dataset.spaceBetween) || 30,
            speed: parseInt(dataset.speed) || 1500,
            watchOverflow: true,
            observer: true,
            observeParents: true,
            lazy: true,
            loop: dataset.loop === 'true',
        };
        
        // Add autoplay if enabled
        if (dataset.autoplay === 'true') {
            config.autoplay = {
                delay: parseInt(dataset.autoplayDelay) || 4000,
                disableOnInteraction: false
            };
        }
        
        // Add pagination if enabled
        if (dataset.pagination === 'true') {
            config.pagination = {
                el: '.swiper-pagination',
                clickable: true
            };
        }
        
        // Add navigation if enabled
        if (dataset.navigation === 'true') {
            config.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            };
        }
        
        // Initialize Swiper
        const swiper = new Swiper(container, config);
        
        // Add hover pause functionality
        $(container).hover(
            () => swiper.autoplay?.stop(),
            () => swiper.autoplay?.start()
        );
    });
};

// Initialize when DOM is ready
$(document).ready(initCustomSwipers);








// $(".popup-modal").magnificPopup({
//   type: "inline",
//   preloader: false,
//   modal: false,
//   midClick: true,
//   callbacks: {
//     beforeOpen: function () {
//       this.st.image.markup = this.st.image.markup.replace(
//         "mfp-figure",
//         "mfp-figure animated " + this.st.el.attr("data-effect")
//       );
//     },
//   },
// });

// $("a.learn-more").click(function () {
//   var interest = $(this).data("interest");
//   var input = $("#contact-us input#" + interest);

//   input.prop("checked", true);
//   input.parent().attr("data-interested", "true");
// });

// $('input[name="interest"]').click(function () {
//   $(this).parent().attr("data-interested", this.checked);
// });

// document.addEventListener("scroll", () => {
//   document.documentElement.dataset.scroll = window.scrollY;
// });

// // // open popup on timeout
// // var $popupTimeOut = 0; // set timeout length
// // $(document).ready(function () {
// //   setTimeout(function() {
// //    if ($('#designing-more').length) {
// //      $.magnificPopup.open({
// //       items: {
// //         src: '#designing-more'
// //       },
// //         type: 'inline'
// //       });
// //      }
// //    }, $popupTimeOut);
// // });

// // $('main section').each(function () {
// //     var $viewport = $(this);
// //
// //     $viewport
// //       .isInViewport({ tolerance: -500, viewport: $viewport })
// //       .addClass('animate');
// // });

// // var headerBottom = $('#header').offset().top + $('#header').height();
// //
// // $(window).on('scroll',function(){
// //
// //     var stop = Math.round($(window).scrollTop());
// //
// //     if (stop > headerBottom) {
// //         $('main').addClass('sticky');
// //     } else {
// //         $('main').removeClass('sticky');
// //     }
// //
// // });

// // $("#header").hover(function() {
// //   $(this).removeClass('finished');
// // }, function() {
// //   $(this).addClass('finished');
// // });
// //
// // $("#master").hover(function() {
// //   $("#header").addClass('paused');
// // }, function() {
// //   $("#header").removeClass('paused');
// // });

// var didScroll = false;
// window.onscroll = doOnScroll;
// var navEl = $("#nav");
// var navTop = navEl.offsetTop;

// setInterval(function () {
//   if (didScroll) {
//     didScroll = false;
//   }
// }, 100);

// function doOnScroll() {
//   didScroll = true;

//   if (window.pageYOffset > navTop) {
//     $(navEl).addClass("sticky");
//   } else {
//     $(navEl).removeClass("sticky");
//   }

//   var windowTop = $(window).scrollTop(),
//     windowHeight = $(window).height();
//   $(".level").css("width", "0px");
//   $(".skill-group").each(function () {
//     var skillsTop = $(this).offset().top;
//     if (windowTop + windowHeight > skillsTop) {
//       $(".level", this).each(function () {
//         var percentage = $(this).attr("data-percent");
//         $(this).animate({ width: percentage }, 2000);
//       });
//     }
//   });
// } // doOnScroll

// $("#nav a").click(function () {
//   var navHeight = $("#nav").height();

//   if ($(this).parents("#nav").hasClass("sticky")) {
//     $("a.anchor").css("top", -navHeight);
//   } else {
//     $("a.anchor").css("top", -navHeight - navHeight);
//   }
// });

// $(".input-group .input").focusout(function () {
//   var text_val = $(this).val();

//   if (text_val === "") {
//     $(this).removeClass("has-value");
//   } else {
//     $(this).addClass("has-value");
//   }
// });

// $(".browserupgrade").click(function () {
//   $(this).hide();
// });

// function anchorLinkHandler(e) {
//   const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);

//   e.preventDefault();
//   const targetID = this.getAttribute("href");
//   const targetAnchor = document.querySelector(targetID);
//   if (!targetAnchor) return;
//   const originalTop = distanceToTop(targetAnchor);

//   window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

//   const checkIfDone = setInterval(function () {
//     const atBottom =
//       window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
//     if (distanceToTop(targetAnchor) === 0 || atBottom) {
//       targetAnchor.tabIndex = "-1";
//       targetAnchor.focus();
//       window.history.pushState("", "", targetID);
//       clearInterval(checkIfDone);
//     }
//   }, 100);
// }

// const linksToAnchors = document.querySelectorAll('a[href^="#"]');

// linksToAnchors.forEach((each) => (each.onclick = anchorLinkHandler));


})(jQuery);